// Load jQuery
import $ from 'jquery';
window.$ = window.jQuery = $;
require('./modules/jquery.blockUI');

jQuery.event.special.touchstart = {
    setup: function( _, ns, handle ) {
        this.addEventListener("touchstart", handle, { passive: !ns.includes("noPreventDefault") });
    }
};
jQuery.event.special.touchmove = {
    setup: function( _, ns, handle ) {
        this.addEventListener("touchmove", handle, { passive: !ns.includes("noPreventDefault") });
    }
};
jQuery.event.special.wheel = {
    setup: function( _, ns, handle ){
        this.addEventListener("wheel", handle, { passive: true });
    }
};
jQuery.event.special.mousewheel = {
    setup: function( _, ns, handle ){
        this.addEventListener("mousewheel", handle, { passive: true });
    }
};

// load fonts
WebFont.load({
    google: { // add &display=swap to end of ONLY END font family, not every single one, other it doesnt load those fonts after
        families: ['Roboto:400,500,700&display=swap']
    },
    active: function () { // helps stop flash of unstyled text
        sessionStorage.fontsLoaded = true
    }
});
(function($) {
    //Load Libraries
    require('flatpickr/dist/flatpickr.min');
    require('magnific-popup/dist/jquery.magnific-popup.min');
    require('select2/dist/js/select2.full.min');
    require('@accessible360/accessible-slick/slick/slick.min');

    //Load Modules
    require('./modules/accordion');
    require('./modules/animatein');
    require('./modules/formInputs');
    require('./modules/menu');
    require('./modules/modal');
    require('./modules/scrollTo');
    require('./modules/searchToggle');
    require('./modules/tabs');
    require('./modules/videoButtonReplacer');
    require('./modules/videoPopup');
    require('./modules/dropdown');
    require('./modules/sliders');
    require('./modules/footer');
    require('./modules/header');
    require('./modules/woocommerce');
    // require('./modules/ajax-add-to-favourites');
    require('./modules/buttonAnimation');
})(jQuery);

