(function ($) {
    var footer_trigger = $('.footer-nav-wrap .footer-nav-title');
    var break_point = 992; // large breakpoint
    var browser_width = window.innerWidth;

    if (browser_width < break_point) {
        footer_trigger.each(function () {
            const this_trigger = $(this);
            const footer_list = this_trigger.closest('.footer-nav').find('ul');

            this_trigger.click(function () {
                if (footer_list.length > 0) {
                    footer_list.toggleClass('__active');
                    $(this).toggleClass('__active');
                }
            });
        })
    }
})(jQuery);