/* Basket preview pop-up */
(function ($) {

    if ($('.basketPreviewWrap').length) {
        $('.basketPreviewButton').on('click', function () {
            var container = $(this).parent('.basketPreviewWrap');

            if ($(this).attr('aria-expanded') === 'false') {
                container.find('.basketPreviewDropdown').fadeIn('fast');
                $(this).attr('aria-expanded', true);
                $(this).addClass('__active');
                $('body').addClass('__modal');
            } else {
                closeBasketPreview();
            }
        });

        // close on keyboard focus out
        $('.basketPreviewClose').on('focusout', function () {
            closeBasketPreview();
        });
        // dont close when click inside the dropdown
        $('.basketPreviewDropdown *').click(function (e) {
            e.stopPropagation();
        });
        // close on click outside
        $(document).on('click', function (event) {
            var button = $('.basketPreviewButton');
            if (button !== event.target && !button.has(event.target).length) {
                closeBasketPreview();
            }
        });
        // close on esc key
        $(document).keyup(function (e) {
            if (e.keyCode == 27) {
                closeBasketPreview();
            }
        });
        // actions to close
        function closeBasketPreview() {
            $('.basketPreviewButton').attr('aria-expanded', false);
            $('.basketPreviewButton').removeClass('__active');
            $('.basketPreviewDropdown').fadeOut('fast');
            $('body').removeClass('__modal');
        }
    }

    /* Favourites preview pop-up */
    if ($('.favouritesPreviewWrap').length) {
        $('.favouritesPreviewButton').on('click', function () {
            var container = $(this).parent('.favouritesPreviewWrap');
            if ($(this).attr('aria-expanded') != 'true') {
                container.find('.favouritesPreviewDropdown').fadeIn('fast');
                $(this).attr('aria-expanded', true);
                $(this).addClass('__active');
                $('main').addClass('__overlay');
                $('body').addClass('__modal');
                console.log('button clicked');
            } else {
                closeFavouritesPreview();
            }
        });
       
        // close on keyboard focus out
        $('.favouritesPreviewClose').on('focusout', function () {
            closeFavouritesPreview();
        });
        // dont close when click inside the dropdown
        $('.favouritesPreviewDropdown *').click(function (e) {
            const target = $(e.target);
            if (target.closest('.favouritesPreviewClose').length) return;
            
            e.stopPropagation();
        });
        // close on click outside
        $(document).on('click', function (event) {
            var button = $('.favouritesPreviewButton');
            if (button !== event.target && !button.has(event.target).length) {
                closeFavouritesPreview();
            }
        });
        // close on esc key
        $(document).keyup(function (e) {
            if (e.keyCode == 27) {
                closeFavouritesPreview();
            }
        });

        function closeFavouritesPreview() {
            $('.favouritesPreviewButton').attr('aria-expanded', false);
            $('.favouritesPreviewButton').removeClass('__active');
            $('.favouritesPreviewWrap').removeClass('__active');
            $('.favouritesPreviewDropdown').fadeOut('fast'); 
            $('main').removeClass('__overlay');
        }
    }
})(jQuery);  
