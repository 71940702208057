(function($) {
    $(document).ready(function() {
        const iconButtons = $('.button.__icon');

        iconButtons.each(function() {
            const thisBtn = $(this);

            thisBtn
                .mouseleave(function() {
                    thisBtn.addClass('__washovered');
                });
        })
    })
})(jQuery);