(function ($) {
    var body = $('body');
    var burger = $('.burger');
    var nav_trigger = $('.nav').find('ul li');
    var meganav_trigger = $('.megamenu').find('.megamenu-nav');
    var megamenu_window = $('.megamenu');
    var break_point = 992;
    var browser_width = window.innerWidth;
    var nav_window = $('<div class="navwindow"></div>');
    body.append(nav_window);
    var nav_wrap = $('.navwrap');

    nav_window.click(function (event) {
        nav_reset();
    });

    //burger click
    burger.click(function (event) {
        event.preventDefault();
        $(this).toggleClass('__active');
        if (body.hasClass('__mobile-menu')) {
            nav_reset();
        } else {
            nav_launch();
        }
    });

    /*nav reset*/
    var nav_reset = function nav_reset() {
        burger.removeClass('__active');
        nav_window.hide();
        body.removeClass('__mobile-menu');
        nav_wrap.removeClass('__active');
        nav_wrap.find('ul').removeClass('__active');
        megamenu_window.removeClass('__active');
    };

    /*nav launch*/
    var nav_launch = function nav_launch() {
        nav_window.show();
        body.addClass('__mobile-menu');
        nav_wrap.addClass('__active');
    };

    // nav dropdown on focus/keyboard navigation
    if (browser_width >= break_point) {
        $('.menu-item').focusin(function () {
            $(this).addClass('__active');
            $(this).children('button').attr('aria-expanded', true);
        });

        $('.menu-item').focusout(function () {
            $(this).removeClass('__active');
            $(this).children('button').attr('aria-expanded', false);
        });

        $('.menu-item').mouseover(function () {
            $(this).children('button').attr('aria-expanded', true);
        });

        $('.menu-item').mouseout(function () {
            $(this).children('button').attr('aria-expanded', false);
        });
        
        $('.menu-item-has-megamenu .menu-item-trigger').click(function() {
            if ($(this).hasClass('__active')) {
                $(this).parent('li').removeClass('__active');
                $(this).siblings('.megamenu').removeClass('__active');
                $(this).removeClass('__active');
                $(this).attr('aria-expanded', false);
            } else {
                $(this).parent('li').addClass('__active');
                $(this).siblings('.megamenu').addClass('__active');
                $(this).addClass('__active');
                $(this).attr('aria-expanded', true);
            }

        });
    }

    if (browser_width < break_point) {
        nav_trigger.click(function (event) {
            const target = $(event.target);
            event.stopPropagation();
            

            if (!target.is('.menu-item-mobile-trigger, .menu-item-trigger')) return;

            event.preventDefault();

            if ($(this).children('ul').length > 0) {
                event.preventDefault();
                $(this)
                    .parents('ul')
                    .removeClass('__active');
                $(this)
                    .children('ul')
                    .addClass('__active');
            }
            if ($(this).children('.megamenu').length > 0) {
                $(this)
                    .parents('ul')
                    .toggleClass('__active');
                $(this)
                    .children('.megamenu')
                    .toggleClass('__active');
                nav_wrap.toggleClass('__active');
                megamenu_window.addClass('__active');
            }
            if ($('.megamenu .__active')) {
                nav_wrap.addClass('__active');
            }
        });
    }

    if (browser_width < break_point) {
        meganav_trigger.click(function (event) {
            const target = $(event.target);
            event.stopPropagation();

            if (!target.hasClass('menu-item-mobile-trigger')) return;

            event.preventDefault();

            if ($(this).children('ul').length > 0) {
                $(this)
                    .parents('.megamenu')
                    .addClass('__active');
                $(this)
                    .children('ul')
                    .toggleClass('__active');
                megamenu_window.addClass('__active');
            }
        });
    }

    var nav_ul = $('ul.child-items');
    var meganav_main = $('.mobile-menu-title-wrap');
    var meganav_child = $('.megamenu-nav-list');
    var back_button = $('<li class="backto"><a href="#">Back</a></li>');
    var back_button_parent = $('<li class="backto"><a href="#">Back</a></li>');
    var meganav_back_button = $('<div class="backtomega"><a href="#">Back</a></div>');

    if (browser_width < break_point) {
        nav_ul.prepend(back_button);
        meganav_main.prepend(back_button_parent);
        meganav_child.prepend(meganav_back_button);
    }

    var back_menu = $('.backto');
    back_menu.click(function (event) {
        event.preventDefault();
        event.stopPropagation();
        megamenu_window.removeClass('__active');
        $(this)
            .parent('ul')
            .removeClass('__active');
        $(this)
            .parents('li')
            .parent('ul')
            .addClass('__active');
        nav_wrap.addClass('__active');
    });

    var back_menu = $('.backtoparent');
    back_menu.click(function (event) {
        event.preventDefault();
        event.stopPropagation();
        megamenu_window.removeClass('__active');
        $(this)
            .parent('ul')
            .removeClass('__active');
        $(this)
            .parents('li')
            .parent('ul')
            .addClass('__active');
        nav_wrap.addClass('__active');
    });

    var back_menu_mega = $('.backtomega');
    back_menu_mega.click(function (event) {
        event.preventDefault();
        event.stopPropagation();
        megamenu_window.addClass('__active');
        console.log('megamenu click');
        $(this)
            .parent('ul')
            .removeClass('__active');
        $(this)
            .parents('li')
            .parent('ul')
            .addClass('__active');
    });

    // find initial browser size
    onResizeReadMoreLayout();
    // if browser is resized, it will either apply the __mobile class (below the breakpoint) or reset all mobile menu settings
    $(window).on('resize', onResizeReadMoreLayout);

    function onResizeReadMoreLayout() {
        if ($(window).width() >= break_point) {
            nav_reset();
        } else {
            body.addClass('__mobile');
        }
    }

    // Touch device check
    const isTouchDevice = () => {
        return window.matchMedia("(pointer: coarse)").matches
    }
    // If desktop and touch device, disable link click and add extra view link
    if (browser_width > break_point && isTouchDevice()) {
        nav_trigger.click(function (event) {
            event.stopPropagation();
            if ($(this).children('ul').length > 0) {
                event.preventDefault();
            }
        });
    }
})(jQuery);