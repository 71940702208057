/* searchtoggle */
(function ($) {
    var searchToggleOpen = $('.searchToggleTrigger');
    var searchBar = $('#searchBarElement');
    var searchBarFocusElement = $('.searchBarFocusElement');
    var searchBarWrap = $('.searchbarWrap');
    var searchToggleClose = $('.searchToggleClose');
    var main = $('main');
    var searchBarHidden = true;
    var searchBarFocused = false;

    searchToggleOpen.on('click', function (e) {
        e.preventDefault();
        searchBarWrap.slideDown('slow');
        main.addClass('__searchactive');
        searchBarFocusElement.val('');
        searchBar.find('*[tabindex=-1]').attr('tabindex', '0');
        searchBar.attr('aria-hidden', searchBarFocused);
        searchBarFocusElement.focus();
        searchBarWrap.addClass('__active');
    });

    searchToggleClose.on('click', function (e) {
        e.preventDefault();
        searchBarWrap.slideUp('slow');
        main.removeClass('__searchactive');
        searchBar.find('*[tabindex=0]').attr('tabindex', '-1');
        searchBar.attr('aria-hidden', searchBarHidden);
        searchToggleOpen.focus();
        searchBarWrap.removeClass('__active');
    })
})(jQuery);