(function ($) {
    $(window).on("load", function () {
        $("a.video, button.video").each(function () {
            const video = $(this);
            const thisVideoSrc = video.data('mfp-src');
            if (thisVideoSrc.includes('https://youtu.be')) {
                const newStr = thisVideoSrc.split('https://youtu.be/')[1];
                const newYoutubeUrl = 'https://www.youtube.com/watch?v=' + newStr;
                $(this).attr('data-mfp-src', newYoutubeUrl);
            }
            $(this).magnificPopup({
                type: "iframe",
                removalDelay: 160,
                preloader: !1,
                fixedContentPos: !1
            })
        });
    });
    // Trigger video pop-up, when block is within the two-column slider
    $('body').on('click', '.two-column-block button.video', function () {
        $(this).magnificPopup({
            type: "iframe",
            removalDelay: 160,
            preloader: !1,
            fixedContentPos: !1
        })
    });
})(jQuery);