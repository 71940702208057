(function ($) {
    /* Standard slider */
    $(".standard-slider .slider-items").each(function () {
        var thisElement = $(this);
        thisElement.slick({
            fade: true,
            dots: true,
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            prevArrow: thisElement.closest('.standard-slider').find('.sliderPrevButton'),
            nextArrow: thisElement.closest('.standard-slider').find('.sliderNextButton'),
            mobileFirst: true,
            responsive: [{
                breakpoint: 992,
                settings: {
                    slidesToShow: 1
                }
            }, ]
        });
    })

    /* Two column slider */
    $(".two-column-block .slider-items").each(function () {
        var thisElement = $(this);
        thisElement.slick({
            fade: true,
            dots: false,
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            prevArrow: thisElement.closest('.two-column-slider').find('.sliderPrevButton'),
            nextArrow: thisElement.closest('.two-column-slider').find('.sliderNextButton'),
            mobileFirst: true,
            responsive: [{
                breakpoint: 992,
                settings: {
                    slidesToShow: 1
                }
            }, ]
        });
    })
    /* Banner slider */
    $('.banner-slider .slider-items').each(function () {
        var sliderParent = $(this).closest('.slider');
        var thisElement = $(this);
        thisElement.slick({
            dots: false,
            fade: false,
            centerMode: false,
            speed: 300,
            infinite: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            prevArrow: thisElement.closest('.banner-slider').find('.sliderPrevButton'),
            nextArrow: thisElement.closest('.banner-slider').find('.sliderNextButton'),
            mobileFirst: true
        });
        if ($(this).find('.slideritem').length > 1) {
            $(this).siblings('.slider-counter').show();
        }
        $(this).on('afterChange', function (event, slick, currentSlide) {
            sliderParent.find('.slider-counter .counterActive').html(currentSlide + 1);
        });
        var sliderItemsNum = $(this).find('.slick-slide').not('.slick-cloned').length;
        sliderParent.find('.slider-counter .counterTotal').html(sliderItemsNum);
    })
    /* Video and image 'media' slider */
    $(".media-slider .slider-items").each(function () {
        var sliderParent = $(this).parent();
        var thisElement = $(this);
        // var currentSlide = $('.slick-current');
        thisElement.slick({
            dots: false,
            centerMode: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            prevArrow: thisElement.closest('.media-slider').find('.sliderPrevButton'),
            nextArrow: thisElement.closest('.media-slider').find('.sliderNextButton'),
            mobileFirst: true,
            responsive: [{
                breakpoint: 992,
                settings: {
                    centerMode: false,
                    draggable: false,
                    slidesToShow: 1,
                }
            }, ]
        });
        if ($(this).find('.slideritem').length > 1) {
            $(this).siblings('.slider-counter').show();
        }
        $(this).on('afterChange', function (event, slick, currentSlide) {
            sliderParent.find('.slider-counter .counterActive').html(currentSlide + 1);
        });
        var sliderItemsNum = $(this).find('.slick-slide').not('.slick-cloned').length;
        sliderParent.find('.slider-counter .counterTotal').html(sliderItemsNum);
    })
    /* Signpost slider */
    $(".signpost-slider .slider-items").each(function () {
        var thisElement = $(this);
        thisElement.slick({
            dots: false,
            centerMode: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            mobileFirst: true,
            responsive: [{
                    breakpoint: 1400,
                    settings: {
                        slidesToShow: 5,
                    }
                },
                {
                    breakpoint: 992,
                    settings: {
                        slidesToShow: 4,
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 3,
                    }
                },
                {
                    breakpoint: 576,
                    settings: {
                        slidesToShow: 2,
                    }
                },
            ]
        });
    })
    /* Homepage signpost mobile slider */
    $(".signpost-blocks.__two .slider-items").each(function () {
        var thisElement = $(this);
        thisElement.slick({
            dots: true,
            centerMode: false,
            variableWidth: false,
            infinite: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            speed: 1000,
            draggable: true,
            mobileFirst: true,
            responsive: [{
                breakpoint: 992,
                settings: "unslick"
            }]
        });
    })
    /* Signpost slider for flex */
    $(".flexibleblocks-block .product-slider .slider-items").each(function () {
        var sliderParent = $(this).parent();
        var thisElement = $(this);
        thisElement.slick({
            dots: false,
            centerMode: true,
            autoplay: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            mobileFirst: true,
            prevArrow: thisElement.closest('.flexibleblocks-block .product-slider').find('.sliderPrevButton'),
            nextArrow: thisElement.closest('.flexibleblocks-block .product-slider').find('.sliderNextButton'),
            responsive: [{
                    breakpoint: 1600,
                    settings: {
                        slidesToShow: 5,
                    }
                },
                {
                    breakpoint: 1400,
                    settings: {
                        slidesToShow: 4,
                    }
                },
                {
                    breakpoint: 992,
                    settings: {
                        slidesToShow: 3,
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 2,
                    }
                },
                {
                    breakpoint: 576,
                    settings: {
                        slidesToShow: 1,
                    }
                },
            ]
        });
        if ($(this).find('.slideritem').length > 1) {
            $(this).siblings('.slider-counter').show();
        }
        $(this).on('afterChange', function (event, slick, currentSlide) {
            sliderParent.find('.slider-counter .counterActive').html(currentSlide + 1);
        });
        var sliderItemsNum = $(this).find('.slick-slide').not('.slick-cloned').length;
        sliderParent.find('.slider-counter .counterTotal').html(sliderItemsNum);
    })
    /* Related products slider */
    $(".woocommerce .product-slider .slider-items").each(function () {
        var sliderParent = $(this).parent();
        var thisElement = $(this);
        thisElement.slick({
            dots: false,
            centerMode: false,
            autoplay: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            mobileFirst: true,
            prevArrow: thisElement.closest('.woocommerce .product-slider').find('.sliderPrevButton'),
            nextArrow: thisElement.closest('.woocommerce .product-slider').find('.sliderNextButton'),
            responsive: [{
                    breakpoint: 992,
                    settings: {
                        slidesToShow: 3,
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 2,
                    }
                },
                {
                    breakpoint: 576,
                    settings: {
                        slidesToShow: 1,
                    }
                },
            ]
        });
        if ($(this).find('.slideritem').length > 1) {
            $(this).siblings('.slider-counter').show();
        }
        $(this).on('afterChange', function (event, slick, currentSlide) {
            sliderParent.find('.slider-counter .counterActive').html(currentSlide + 1);
        });
        var sliderItemsNum = $(this).find('.slick-slide').not('.slick-cloned').length;
        sliderParent.find('.slider-counter .counterTotal').html(sliderItemsNum);
    })
    /* Related products slider */
    $(".product-blocks .slider-items").each(function () {
        var sliderParent = $(this).parent();
        var thisElement = $(this);
        thisElement.slick({
            dots: true,
            centerMode: true,
            variableWidth: false,
            infinite: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            mobileFirst: true,
            responsive: [{
                    breakpoint: 992,
                    settings: "unslick"
                },
                {
                    breakpoint: 576,
                    settings: {
                        slidesToShow: 2,
                        centerMode: false
                    }
                }
            ]
        });
        if ($(this).find('.slideritem').length > 1) {
            $(this).siblings('.slider-counter').show();
        }
        $(this).on('afterChange', function (event, slick, currentSlide) {
            sliderParent.find('.slider-counter .counterActive').html(currentSlide + 1);
        });
        var sliderItemsNum = $(this).find('.slick-slide').not('.slick-cloned').length;
        sliderParent.find('.slider-counter .counterTotal').html(sliderItemsNum);
    })
    $('.TabTrigger').click(function () {
        $(".product-blocks .slider-items").refresh;
        $('.product-blocks .slider-items').get(0).slick.setPosition();
    })
})(jQuery);