// Default select with no search
(function ($) {
    $(document).ready(function() {
        $('.__select select').each(function () {
            const dropdownId = $(this).parent();

            $(this).select2({
                containerCssClass: 'select2-css',
                minimumResultsForSearch: Infinity,
                dropdownParent: dropdownId,
            })
        });
    });

    // Copy url on click
    var $temp = $("<input>");
    var $url = $(location).attr('href');
    $('.banner-details-share-icon.__copy').on('click', function() {
        $("body").append($temp);
        $temp.val($url).select();
        document.execCommand("copy");
        $temp.remove();
        alert("URL copied!");
    });

})(jQuery);
