(function ($) {
    $("a.video").each(function () {
        const video = $(this);
        const thisInner = video.html();

        // Set attributes
        classAttributes = `${video.attr('class') && `class="${video.attr('class')}"`}`;
        dataAttributes = `${video.data('mfp-src') && `data-mfp-src="${video.data('mfp-src')}"`}`;
        // Apply attributes
        const buttonAttributes = `${classAttributes} ${dataAttributes}`;

        // Construct and replace with button
        video.replaceWith('<button ' + buttonAttributes + '>' + thisInner + '</button>');
    });
})(jQuery);